














































































import Vue from 'vue'
import {
  mapActions,
  mapMutations,
  mapState
} from 'vuex'
import CatalogChildCategories from '@/components/Catalogs/CatalogChildCategories.vue'
import CatalogCategories from '@/components/Catalogs/CatalogCategories.vue'
import CatalogProducts from '@/components/Catalogs/CatalogProducts.vue'
import ModalEditChildCategories from '@/components/Catalogs/ModalEditChildCategories.vue'
import ModalEditProduct from '@/components/Catalogs/ModalEditProduct.vue'
import ModalEditCategory from '@/components/Catalogs/ModalEditCategory.vue'
import ModalEditChildCategory from '@/components/Catalogs/ModalEditChildCategory.vue'
import ModalEditCatalog from '@/components/Catalogs/ModalEditCatalog.vue'
import ModalAddPartner from '@/components/Catalogs/ModalAddPartner.vue'
import Partners from '@/components/Catalogs/Partners.vue'
import Add from '@/components/Catalogs/Add.vue'

export default Vue.extend({
  name: 'Catalog',

  components: {
    ModalEditProduct,
    CatalogChildCategories,
    CatalogProducts,
    CatalogCategories,
    Add,
    ModalEditChildCategories,
    ModalEditCategory,
    ModalEditChildCategory,
    ModalEditCatalog,
    ModalAddPartner,
    Partners
  },

  data () {
    return {
      profileId: null,
      catalogId: null,
      isPartnerPage: false
    }
  },

  computed: {
    ...mapState('catalog', [
      'catalog',
      'catalogs',
      'catalogCategories',
      'selectedCatalogCategory',
      'selectedCatalogChildCategory'
    ]),

    actionsButtons () {
      return [
        {
          label: 'Создать товар',
          action: 'add-product'
        },
        {
          label: 'Создать категорию',
          action: 'add-child-category'
        },
        {
          label: 'Создать раздел',
          action: 'add-category'
        },
        {
          label: 'Создать каталог',
          action: 'add-catalog'
        }
      ]
    },

    ...mapState('pgt', [
      'pgtProfile',
      'partners'
    ])

    // breadcrumbItems () {
    //   return [
    //     {
    //       text: 'Активные бп',
    //       to: { name: 'profiles-active' }
    //     },
    //     {
    //       text: this.profile.username,
    //       to: { name: 'profile-main', params: { profileId: this.$route.params.profileId } }
    //     },
    //     {
    //       text: 'Каталоги',
    //       to: { name: 'profile-catalogs', params: { profileId: this.$route.params.profileId } }
    //     },
    //     {
    //       text: this.catalog.name,
    //       active: true
    //     }
    //   ]
    // }
  },

  watch: {
    selectedCatalogCategory (val) {
      if (!val.id) return

      this.setCatalogChildCategoryProducts([])
      this.setSelectedCatalogChildCategory({})
      this.fetchCatalogChildCategories()
    },

    async isPartnerPage (boolean) {
      this.clearData()
      if (boolean && !this.partners.length) {
        await this.getPgtPartners({ id: this.pgtProfile.pgt_profile.id })
        if (!this.partners.length) {
          this.profileId = ''
          return
        }
        await this.getCatalogs({
          businessProfileId: this.partners[0]?.profile_data?.id
        })
        if (!this.catalogs.length) return this.$toast.error('У партнера пока что нет каталога.')
        this.profileId = this.partners[0]?.profile_data?.id
        this.catalogId = this.catalogs[0].id
      } else {
        this.profileId = this.pgtProfile.business_profile.id
        this.catalogId = this.$route.params.catalogId
      }
      await this.fetchCatalog()
      await this.fetchCatalogCategories()
    }
  },

  async created () {
    await this.fetchCatalog()
    await this.fetchCatalogCategories()
    // this.setBreadcrumbItems(this.breadcrumbItems)
  },

  beforeDestroy () {
    this.clearData()
  },

  methods: {
    ...mapMutations('common', [
      'setLoading'
    ]),

    ...mapMutations('catalog', [
      'setCatalogChildCategoryProducts',
      'setCatalogChildCategories',
      'setCatalogCategories',
      'setSelectedCatalogCategory',
      'setSelectedCatalogChildCategory'
    ]),

    ...mapMutations('pgt', [
      'setPgtPartners'
    ]),

    // ...mapActions('common', [
    //   'setBreadcrumbItems'
    // ]),

    ...mapActions('catalog', [
      'getCatalog',
      'getCatalogs',
      'getCatalogCategories',
      'getCatalogChildCategories'
    ]),

    ...mapActions('pgt', [
      'getPgtPartners',
      'updatePgtPartner',
      'updatePgtPartnersPositions'
    ]),

    clearData () {
      this.setCatalogChildCategoryProducts([])
      this.setCatalogChildCategories([])
      this.setCatalogCategories([])
      this.setSelectedCatalogCategory({})
      this.setSelectedCatalogChildCategory({})
      this.setPgtPartners([])
    },

    async fetchCatalog () {
      try {
        this.setLoading(true)
        await this.getCatalog({
          profileId: this.profileId || this.pgtProfile.business_profile.id,
          catalogId: this.catalogId || this.$route.params.catalogId
        })
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    async fetchCatalogCategories () {
      try {
        this.setLoading(true)
        await this.getCatalogCategories({
          catalogId: this.catalogId || this.$route.params.catalogId
        })
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    async fetchCatalogChildCategories () {
      try {
        this.setLoading(true)
        await this.getCatalogChildCategories()
      } catch (error) {
        console.info(error)
        this.$toast.error(error.message)
      } finally {
        this.setLoading(false)
      }
    },

    handleAddProduct () {
      this.$bvModal.show('modal-edit-product')
    },

    handleAddCategory () {
      this.$bvModal.show('modal-edit-catalog-category')
    },

    handleAddChildCategory () {
      this.$bvModal.show('modal-edit-catalog-child-category')
    },

    handleAddCatalog () {
      this.$bvModal.show('modal-edit-catalog')
    },

    async handlePartnerToggleVisibility ({ hideAction, profileId }) {
      const result = await this.updatePgtPartner({
        pgtId: this.pgtProfile.pgt_profile.id,
        profileId,
        isHidden: hideAction
      })

      if (result.status < 200 || result.status >= 300) {
        return this.$toast.error(result.data.detail.message)
      }
      await this.getPgtPartners({ id: this.pgtProfile.pgt_profile.id })
      this.$toast.success(hideAction ? 'Партнер скрыт' : 'Партнер вновь отображается')
    },
    async handleSelectPartner (id: string) {
      await this.getCatalogs({
        businessProfileId: id
      })
      if (!this.catalogs.length) return this.$toast.error('У партнера пока что нет каталога.')
      this.profileId = id
      this.catalogId = this.catalogs[0].id
      this.setCatalogChildCategoryProducts([])
      this.setCatalogChildCategories([])
      this.setCatalogCategories([])
      this.setSelectedCatalogCategory({})
      this.setSelectedCatalogChildCategory({})
      await this.fetchCatalog()
      await this.fetchCatalogCategories()
    },
    async handleSortPartners ({ to, after }) {
      const result = await this.updatePgtPartnersPositions({
        pgtId: this.pgtProfile.pgt_profile.id,
        to,
        after
      })

      if (result.status < 200 || result.status >= 300) {
        return this.$toast.error(result.data.detail.message)
      }
      await this.getPgtPartners({ id: this.pgtProfile.pgt_profile.id })
      this.$toast.success('Последовательность партнеров успешно изменена!')
    },
    async handleUpdatePartners () {
      await this.getPgtPartners({ id: this.pgtProfile.pgt_profile.id })
    }
  }
})
