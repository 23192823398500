


































import Vue, { PropType } from 'vue'
import { UpdateProfileBody } from '@/store/modules/pgt/types'
import PartnerCard from './PartnerCard.vue'
import AddBlock from '@/components/AddBlock.vue'
import { SlickList, SlickItem } from 'vue-slicksort'
import { cloneDeep } from '@/utils/functions'
import { mapActions } from 'vuex'

export default Vue.extend({
  name: 'Partners',
  props: {
    partners: {
      type: Array as PropType<UpdateProfileBody[]>,
      default: () => ([])
    },
    activeID: {
      type: String,
      default: ''
    }
  },

  components: {
    PartnerCard,
    AddBlock,
    SlickItem,
    SlickList
  },

  data () {
    return {
      beforeOrderPartners: [] as UpdateProfileBody[]
    }
  },

  methods: {
    ...mapActions('pgt', [
      'updatePgtPartnersPoritions'
    ]),
    async handlePartnerToggleVisibility ({ hideAction, profileId }) {
      this.$emit('hide-partner', { hideAction, profileId })
    },
    handleSelectPartner (id: string) {
      this.$emit('select-partner', id)
    },
    shouldCancelStart (e) {
      return !e.target.classList.contains('partner-card__drag')
    },
    onSortStart (event: any) {
      this.sortedItem = this.partners[event.index]
      this.beforeOrderPartners = cloneDeep(this.partners)
    },
    async onSorted (newSorteredList: UpdateProfileBody[]) {
      const sortedItemIndex = this.partners.findIndex((item: UpdateProfileBody) => item.profile_data.id === this.sortedItem.profile_data.id)
      let newIndex = 0

      // Если сортируемый элемент не поставили в начало (0 index),
      // то задаем новый индекс
      if (newSorteredList[0].profile_data.id !== this.sortedItem.profile_data.id) {
        newIndex = newSorteredList.findIndex(item => item.profile_data.id === this.partners[sortedItemIndex].profile_data.id)
      }

      // Если новый индекс совпадает со старым, то ничего не делаем
      if (sortedItemIndex === newIndex) return

      this.$emit('sort-partners', {
        to: this.sortedItem.profile_data.id,
        after: newIndex === 0 ? null : newSorteredList[newIndex - 1].profile_data.id
      })
    }
  }
})
