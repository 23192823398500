













































import Vue, { PropType } from 'vue'
import { PGTProfile } from '@/store/modules/pgt/types'
import { S3_URL } from '@/common/constants'

export default Vue.extend({
  name: 'CatalogPartnerCard',

  props: {
    partner: {
      type: Object as PropType<PGTProfile>,
      default: () => {}
    },
    activeId: {
      type: String,
      default: ''
    }
  },

  computed: {
    imageStyle () {
      let imageURL = this.partner.avatar_url
      if (!imageURL) {
        imageURL = `${S3_URL}/default_avatar.png`
      }
      return { backgroundImage: `url(${imageURL})` }
    }
  },

  methods: {
    toggleVisible (boolean) {
      this.$emit('hide-partner', { hideAction: boolean, profileId: this.partner.profile_data.id })
    }
  }
})
