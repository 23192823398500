






















































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { S3_URL } from '@/common/constants'
import ValidatedBFormInput from '@/components/ui/ValidatedBFormInput.vue'
import Loading from '@/components/Loading/index.vue'

export default Vue.extend({
  name: 'ModalAddPartner',

  components: {
    Loading,
    ValidatedBFormInput
  },

  data () {
    return {
      partner: {
        id: '',
        username: '',
        name: ''
      },
      search: '',
      loading: false,
      showDropdown: false,
      debounce: null,
      profilesList: []
    }
  },

  mounted () {
    this.getProfilesList()
  },

  computed: {
    ...mapState('profiles', [
      'profiles'
    ]),
    ...mapState('pgt', [
      'pgtProfile',
      'partners'
    ]),
    imageStyle () {
      let imageURL = this.partner.avatar_url
      if (!imageURL) {
        imageURL = `${S3_URL}/default_avatar.png`
      }
      return { backgroundImage: `url(${imageURL})` }
    }
  },

  watch: {
    async search (value) {
      clearTimeout(this.debounce)
      this.debounce = await setTimeout(async () => {
        this.getProfilesList()
      }, 250)
    },
    profiles () {
      this.sortPartners()
    }
  },

  methods: {
    ...mapActions('profiles', [
      'getProfiles'
    ]),
    ...mapActions('pgt', [
      'addPgtPartner'
    ]),

    address (profile) {
      return profile.profile_data?.location?.address || ''
    },

    onModalHidden () {
      this.reset()
    },

    reset () {
      this.partner = {
        id: '',
        username: '',
        name: ''
      }
      this.search = ''
      this.debounce = null
      this.getProfilesList()
      this.showDropdown = false
    },

    async onSubmit () {
      if (!this.partner.id) return this.$toast.error('Сперва выберите партнера!')
      if (!this.partner?.profile_data?.id) return this.$toast.error('Данный профиль не является бизнесом!')
      this.loading = true

      const result = await this.addPgtPartner({
        pgtId: this.pgtProfile.pgt_profile.id,
        partnerId: this.partner.profile_data.id
      })
      this.loading = false
      if (result.status < 200 || result.status >= 300) {
        this.$toast.error(result.data.detail)
        return
      }
      this.$toast.success('Партнер успешно добавлен!')
      this.$emit('update-partners')
      this.$bvModal.hide('modal-add-partner')
    },

    async getProfilesList () {
      try {
        this.loading = true
        const payload = {
          // isAllowedForBusiness: true,
          offset: 0,
          limit: 20,
          filter_field: '',
          filter_value: '',
          profile_type: 'business'
        }
        if (this.search) {
          payload.filter_field = 'username'
          payload.filter_value = this.search
        }
        await this.getProfiles(payload)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },

    sortPartners () {
      const partnersIds = []
      this.partners.forEach(function (partner) {
        partnersIds.push(partner.id)
      })
      this.profilesList = this.profiles.filter(profile => !partnersIds.includes(profile.id))
    },

    selectProfile (profile) {
      this.search = profile.username
      this.partner = profile
      this.showDropdown = false
    }
  }
})
